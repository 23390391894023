'use client';

import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { usePathname } from 'next/navigation';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import { Caret, HeroIconsIcon, IconComponent, PolyglotIcon } from '../icons/icons';
import { SocialIcons } from '../Footer';

import { SecondaryNavigation } from './SecondaryNavigation/SecondaryNavigation';
import { Logo } from './Logo';
import { ExpandedNavigation } from './ExpandedNavigation';

import { useIsScrolledToTop } from '@/hooks/useIsScrolledToTop';
import { LocaleKey } from '@/constants/locales';
import { getLocaleSpecificUrl } from '@/utils/locale';
import { MaxWidthContainer } from '@/components/Layouts/MaxWidthContainer';
import { Translation, TranslationOverwrites } from '@/components/Translation';
import { Article, DocumentationItem } from '@/data/sanity.api';

type PublicNavigationProps = {
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
  articles: Article[];
  documentation: DocumentationItem[];
};

export type NavigationItem = {
  page_title: string;
  translate_title?: boolean;
  non_default_locale_translated_title?: string;
  translate_description?: boolean;
  translation_overwrites?: TranslationOverwrites;
  page_url?: string;
  featured?: boolean;
  description?: string;
  icon?: IconComponent | HeroIconsIcon;
  bg?: string;
  readMore?: boolean;
  children?: NavigationItem[];
  hidden_from_india?: boolean;
};

const meta = {
  website_name: 'VocalStack',
  website_description: 'makes perfect sense',
};

const nav: NavigationItem[] = [
  {
    page_title: 'Solutions',
    translate_title: true,
    children: [
      {
        page_url: '/polyglot',
        page_title: 'Polyglot',
        featured: true,
        translate_description: true,
        description:
          'Speak in any language, and others can read the live transcribed text in any language in real time.',
        icon: PolyglotIcon,
        bg: '/navigation/polyglot-bg.webp',
      },
      {
        page_url: '/business',
        page_title: 'Business',
        featured: true,
        description:
          'Leverage the power of VocalStack by seamlessly integrating its services into your existing infrastructure.',
        translate_description: true,
        bg: '/navigation/business-bg.webp',
      },
      { translate_title: true, page_title: 'Audio to Text Transcription' },
      { translate_title: true, page_title: 'Live Audio Transcription' },
      { translate_title: true, page_title: 'Live Stream Transcription and Translation' },
      { translate_title: true, page_title: 'Text Summarization' },
    ],
  },
  {
    page_url: '/articles',
    page_title: 'Articles',
    readMore: true,
    translate_title: true,
    translation_overwrites: {
      es: 'Artículos',
    },
  },
  {
    page_title: 'API',
    children: [
      {
        page_url: '/documentation',
        page_title: 'Documentation',
        featured: true,
        translate_title: true,
        translate_description: true,
        description: 'Technical documentation for how to use VocalStack services programmatically.',
        bg: '/navigation/documentation-bg.webp',
      },
      {
        page_url: '/api',
        page_title: 'API Reference',
        featured: true,
        translate_title: true,
        translate_description: true,
        description: 'A Swagger UI for documenting and live testing the VocalStack RESTful API using an API key.',
        bg: '/navigation/api-bg.webp',
      },
    ],
  },
  {
    page_url: '/pricing',
    page_title: 'Pricing',
    non_default_locale_translated_title: 'Prices',
    translate_title: true,
    translation_overwrites: {
      es: 'Precios',
    },
  },
  {
    page_url: '/contact',
    page_title: 'Contact',
    translate_title: true,
    hidden_from_india: true,
  },
];

const getNavigation = (localeKey: LocaleKey, nav: NavigationItem[], currentPathName: string | null): NavigationItem[] =>
  nav
    // add "current" flag
    .map((item) => ({ ...item, current: getLocaleSpecificUrl(localeKey, item.page_url ?? '') === currentPathName }))
    // add locales
    .map((item) => ({
      ...item,
      page_url: item.page_url && getLocaleSpecificUrl(localeKey, item.page_url),
      children: item.children?.map((child) => ({
        ...child,
        page_url: child.page_url && getLocaleSpecificUrl(localeKey, child.page_url),
      })),
    }))
    .filter((item) => !item.hidden_from_india || localeKey !== 'hi');

export const PublicNavigation = (props: PublicNavigationProps) => {
  const { localeKey, hasDarkThemeCookie, articles, documentation } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [expandedItem, setExpandedItem] = useState<NavigationItem | undefined>();
  const pathname = usePathname();
  const navigation = getNavigation(localeKey, nav, pathname);
  const isHome = getLocaleSpecificUrl(localeKey, '/') === pathname;
  const hasBoxShadowDisabledOnScrollTop = [
    getLocaleSpecificUrl(localeKey, '/'),
    getLocaleSpecificUrl(localeKey, '/polyglot'),
  ].includes(String(pathname));
  const { isScrolledToTop } = useIsScrolledToTop();

  const articlesNav = navigation.find((item) => item.page_title === 'Articles');
  if (articlesNav) {
    articlesNav.children = articles.map((a, index) => ({
      page_title: String(a.title),
      page_url: a.url,
      featured: index <= 1,
      description: a.shortDescription,
      bg: a.featuredImage,
    }));
  }

  const documentationNav = navigation.find((item) => item.page_title === 'API');
  if (documentationNav?.children) {
    documentationNav.children = [
      ...documentationNav.children,
      ...documentation.map((d) => ({
        page_title: String(d.title),
        page_url: d.url,
        featured: false,
        description: d.shortDescription,
      })),
    ];
  }

  useEffect(() => {
    setTimeout(() => setHasLoaded(true), 10);
    window.addEventListener('scroll', () => setExpandedItem(undefined));
  }, []);

  useEffect(() => {
    !mobileMenuOpen && setExpandedItem(undefined);
  }, [mobileMenuOpen]);

  return (
    <header
      className={classNames(
        'fixed inset-x-0 top-0 z-[100] select-none transition-all duration-300',
        'text-primary-600 dark:text-inherit',
        {
          'bg-gray-100/80 shadow-xl backdrop-blur-lg dark:bg-background-900/80':
            !hasBoxShadowDisabledOnScrollTop || !isScrolledToTop,
          '[&~*]:pointer-events-none [&~*]:blur': expandedItem,
        },
        'print:!hidden',
      )}
      suppressHydrationWarning
    >
      <MaxWidthContainer className="hover-container container mx-auto flex items-center justify-between py-2 print:!hidden md:py-3 lg:py-6">
        <div>
          <div
            className={classNames('relative inline-flex flex-col', { 'mb-5': isHome && isScrolledToTop })}
            suppressHydrationWarning
          >
            <a href={getLocaleSpecificUrl(localeKey, '/')} title="Home">
              <Logo
                showBeta
                className={classNames('w-auto object-contain', 'min-w-[170px] xl:min-w-[217px]', {
                  'h-8 sm:h-10': isHome && isScrolledToTop,
                  'h-8 sm:h-8': !isHome || !isScrolledToTop,
                  'transition-all': hasLoaded,
                })}
              />
            </a>
            {isHome && (
              <p
                suppressHydrationWarning
                className={classNames(
                  'text-balance text-center text-sm tracking-wide text-text-950/80 transition-all dark:text-white/50 sm:text-base',
                  'absolute -bottom-6 left-0 right-0 sm:-bottom-8',
                  { 'opacity-0': !isScrolledToTop },
                )}
              >
                {meta.website_description}
              </p>
            )}
          </div>
        </div>

        <div className="mx-auto hidden gap-1 lg:flex xl:justify-center xl:gap-6">
          {navigation.map((item) => (
            <span
              key={item.page_title}
              onMouseLeave={(e) =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                !(e.relatedTarget as any)?.closest?.('.hover-container') && setExpandedItem(undefined)
              }
            >
              <a
                href={item.page_url}
                onMouseEnter={() => setExpandedItem(item.children ? item : undefined)}
                className={classNames(
                  'flex gap-2 whitespace-nowrap rounded-lg px-3 py-2 text-base font-semibold leading-6 tracking-wide hover:bg-primary-500/5 dark:hover:bg-white/5',
                  {
                    'bg-primary-500/5 dark:bg-white/5': expandedItem?.page_title === item.page_title,
                  },
                )}
              >
                <Translation
                  enabled={!!item.translate_title}
                  name={`nav.item.${item.page_title}`}
                  nonDefaultLocaleText={item.non_default_locale_translated_title}
                  overwrites={item.translation_overwrites}
                >
                  {item.page_title}
                </Translation>
                {item.children && (
                  <Caret
                    className={classNames('w-3 transition-all transform-gpu', {
                      'rotate-90': expandedItem?.page_title === item.page_title,
                    })}
                  />
                )}
              </a>
              <ExpandedNavigation
                item={item}
                expanded={expandedItem?.page_title === item.page_title}
                className="w-full"
              />
            </span>
          ))}
        </div>
        <SecondaryNavigation
          className="hidden min-w-[244px] shrink-0 md:flex lt-lg:ml-auto lt-lg:mr-4"
          hasDarkThemeCookie={hasDarkThemeCookie}
          localeKey={localeKey}
          showDashboardButton
        />

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
            title="Navigation"
          >
            <span className="sr-only">
              <Translation name="nav.sr.open">Open main menu</Translation>
            </span>
            <Bars3Icon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
      </MaxWidthContainer>
      <Dialog as="div" className="print:!hidden lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel
          className={classNames(
            'fixed inset-y-0 right-0 z-50 w-full overflow-y-auto px-6 py-6 transition-colors sm:max-w-sm sm:ring-1 sm:ring-white/10',
            'bg-gray-200 dark:bg-background-900',
          )}
        >
          {mobileMenuOpen && (
            <>
              <div className="relative flex items-center justify-between">
                <a href={getLocaleSpecificUrl(localeKey, '/')} className="-m-1.5 p-1.5 sm:hidden">
                  <span className="sr-only">{meta.website_name}</span>
                  <Logo className="h-10 w-auto transition-all" />
                </a>
                <button
                  type="button"
                  className="right-0 top-0 -m-2.5 rounded-md p-2.5 sm:absolute"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">
                    <Translation name="nav.sr.close">Close menu</Translation>
                  </span>
                  <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              </div>
              <div className="mb-20 mt-10 flow-root">
                <div className="flex flex-col gap-1">
                  {navigation.map((item) => (
                    <span key={item.page_title}>
                      <a
                        href={item.children || item.readMore ? undefined : item.page_url}
                        onClick={(event) => {
                          setExpandedItem(expandedItem?.page_title !== item.page_title ? item : undefined);
                          const element = event.currentTarget;
                          setTimeout(() => element?.scrollIntoView({ behavior: 'smooth', block: 'start' }));
                        }}
                        className={classNames(
                          '-mx-3 flex cursor-pointer gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-7 tracking-wide hover:bg-white/10',
                          {
                            'bg-primary-600/10 dark:bg-white/5': expandedItem?.page_title === item.page_title,
                          },
                        )}
                      >
                        {!item.translate_title ? (
                          item.page_title
                        ) : (
                          <Translation name={`nav.item.${item.page_title}`} overwrites={item.translation_overwrites}>
                            {item.non_default_locale_translated_title ?? item.page_title}
                          </Translation>
                        )}
                        {item.children && (
                          <Caret
                            className={classNames('w-3 transition-all transform-gpu', {
                              'rotate-90': expandedItem?.page_title === item.page_title,
                            })}
                          />
                        )}
                      </a>
                      {expandedItem?.page_title === item.page_title && (
                        <ExpandedNavigation className="-mx-4 w-[calc(100%+2rem)] sm:w-full" item={item} expanded />
                      )}
                    </span>
                  ))}

                  <SocialIcons />

                  <SecondaryNavigation
                    className="!mx-auto !items-center py-6"
                    hasDarkThemeCookie={hasDarkThemeCookie}
                    localeKey={localeKey}
                    showDashboardButton
                  />
                </div>
              </div>
            </>
          )}
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
