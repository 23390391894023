'use client';

import classNames from 'classnames';
import { SignUpButton, useAuth } from '@clerk/nextjs';
import { CursorArrowRaysIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';

import { Prices } from '@/constants/pricingData';
import { ButtonAnchor, GhostOnDarkAnchor, GhostButton } from '@/components/Fields/Buttons';
import { Translation } from '@/components/Translation';
import { useBilling } from '@/context/BillingContext';
import { useEffect, useState } from 'react';

type PricingButtonProps = {
  plan: Prices[keyof Prices];
  className?: string;
  location: 'cards' | 'table';
  children?: React.ReactNode;
};

export const PricingButton = ({ plan, className, location, children }: PricingButtonProps) => {
  const { isSignedIn, isLoaded } = useAuth();
  const { billing, premium_yearly_payment_link, premium_monthly_payment_link, billingCycle } = useBilling();

  const [isReallySignedIn, setIsReallySignedIn] = useState(false); // <-- Need this to force re-render

  useEffect(() => {
    if (isSignedIn && isLoaded) {
      setIsReallySignedIn(true);
    }
  }, [isSignedIn, isLoaded]);

  const signedInUserHasButtons = premium_yearly_payment_link || premium_monthly_payment_link;
  const { label } = plan;
  const meta_name = billing?.active_plan.meta_name;
  const ctaButtonCSS = 'px-5 py-3.5';

  const clearBilling = () => {
    localStorage.removeItem('billing');
    document.cookie = 'LAST_BILLING_UPDATE=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const Btn = location === 'cards' ? GhostOnDarkAnchor : GhostButton;

  if (signedInUserHasButtons && meta_name && label === 'Hobby' && meta_name === 'Premium') {
    return <GhostButton className={classNames('invisible mx-auto mt-auto h-11 lg:mx-0', ctaButtonCSS, className)} />;
  }

  if (label === billing?.active_plan.meta_name) {
    return (
      <GhostButton
        className={classNames(
          'pointer-events-none mt-auto h-11 touch-none font-extralight italic lt-lg:mx-auto',
          {
            '!bg-gray-300/50 dark:!bg-gray-700/50': label === 'Hobby',
            '!bg-green-300 dark:!bg-green-700': label !== 'Hobby',
          },
          ctaButtonCSS,
          className,
        )}
      >
        <Translation name="pricing.button.your-current-plan">Your current plan</Translation>
      </GhostButton>
    );
  }

  if (label === 'Hobby') {
    const btn = (signedIn: boolean) => (
      <Btn href={signedIn === false ? undefined : '/dashboard'} className={classNames(className, ctaButtonCSS)}>
        <CursorArrowRaysIcon className="inline h-6 w-6" aria-hidden="true" />
        {children || <Translation name="pricing.button.get-this-plan">Get this plan</Translation>}
      </Btn>
    );
    const signedOutContent = (
      <SignUpButton mode="modal" forceRedirectUrl="/dashboard">
        {btn(false)}
      </SignUpButton>
    );
    return (
      <div suppressHydrationWarning>
        <div className={classNames({ hidden: !isReallySignedIn })}>{btn(true)}</div>
        <div className={classNames({ hidden: isReallySignedIn })}>{signedOutContent}</div>
      </div>
    );
  }
  if (label === 'Premium') {
    const btn = (signedIn: boolean) => (
      <ButtonAnchor
        onClick={clearBilling}
        href={
          !signedIn
            ? undefined
            : billingCycle === 'Monthly'
              ? premium_monthly_payment_link
              : premium_yearly_payment_link
        }
        className={classNames(ctaButtonCSS, className)}
      >
        <CursorArrowRaysIcon className="inline h-6 w-6" aria-hidden="true" />
        {children || <Translation name="pricing.button.get-this-plan">Get this plan</Translation>}
      </ButtonAnchor>
    );
    const signedOutContent = (
      <SignUpButton mode="modal" forceRedirectUrl="/dashboard">
        {btn(false)}
      </SignUpButton>
    );
    return (
      <>
        <div className={classNames({ hidden: !isReallySignedIn })}>{btn(true)}</div>
        <div className={classNames({ hidden: isReallySignedIn })}>{signedOutContent}</div>
      </>
    );
  }
  if (label === 'Enterprise') {
    return (
      <Btn href="/contact" className={classNames(className, ctaButtonCSS)}>
        <ChatBubbleLeftRightIcon className="inline h-6 w-6" aria-hidden="true" />
        <Translation name="pricing.button.lets-talk">{"Let's talk"}</Translation>
      </Btn>
    );
  }
  return null;
};
